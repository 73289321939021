.leaflet-draw-section {
  position: relative;
}
.leaflet-draw-toolbar {
  margin-top: 12px;
}
.leaflet-draw-toolbar-top {
  margin-top: 0;
}
.leaflet-draw-toolbar-notop a:first-child {
  border-top-right-radius: 0;
}
.leaflet-draw-toolbar-nobottom a:last-child {
  border-bottom-right-radius: 0;
}

.leaflet-draw a {
  display: block;
  text-align: center;
  text-decoration: none;
}
.leaflet-draw a .sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.leaflet-draw-actions {
  display: none;
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  left: 26px;
  top: 0;
  white-space: nowrap;
}
.leaflet-touch .leaflet-draw-actions {
  left: 32px;
}
.leaflet-right .leaflet-draw-actions {
  right: 26px;
  left: auto;
}
.leaflet-touch .leaflet-right .leaflet-draw-actions {
  right: 32px;
  left: auto;
}
.leaflet-draw-actions li {
  display: inline-block;
}
.leaflet-draw-actions li:first-child a {
  border-left: 0;
}
.leaflet-draw-actions li:last-child a {
  -webkit-border-radius: 0 4px 4px 0;
  border-radius: 0 4px 4px 0;
}
.leaflet-right .leaflet-draw-actions li:last-child a {
  -webkit-border-radius: 0;
  border-radius: 0;
}
.leaflet-right .leaflet-draw-actions li:first-child a {
  -webkit-border-radius: 4px 0 0 4px;
  border-radius: 4px 0 0 4px;
}
.leaflet-draw-actions a {
  background-color: #919187;
  border-left: 1px solid #aaa;
  color: #fff;
  font: 11px/19px "Helvetica Neue", Arial, Helvetica, sans-serif;
  line-height: 28px;
  text-decoration: none;
  padding-left: 10px;
  padding-right: 10px;
  height: 28px;
}
.leaflet-touch .leaflet-draw-actions a {
  font-size: 12px;
  line-height: 30px;
  height: 30px;
}
.leaflet-draw-actions-bottom {
  margin-top: 0;
}
.leaflet-draw-actions-top {
  margin-top: 1px;
}
.leaflet-draw-actions-top a,
.leaflet-draw-actions-bottom a {
  height: 27px;
  line-height: 27px;
}
.leaflet-draw-actions a:hover {
  background-color: #a0a098;
}
.leaflet-draw-actions-top.leaflet-draw-actions-bottom a {
  height: 26px;
  line-height: 26px;
}
.leaflet-draw-toolbar .leaflet-draw-draw-polyline {
  background-position: -2px -2px;
}
.leaflet-touch .leaflet-draw-toolbar .leaflet-draw-draw-polyline {
  background-position: 0 -1px;
}
.leaflet-draw-toolbar .leaflet-draw-draw-polygon {
  background-position: -31px -2px;
}
.leaflet-touch .leaflet-draw-toolbar .leaflet-draw-draw-polygon {
  background-position: -29px -1px;
}
.leaflet-draw-toolbar .leaflet-draw-draw-rectangle {
  background-position: -62px -2px;
}
.leaflet-touch .leaflet-draw-toolbar .leaflet-draw-draw-rectangle {
  background-position: -60px -1px;
}
.leaflet-draw-toolbar .leaflet-draw-draw-circle {
  background-position: -92px -2px;
}
.leaflet-touch .leaflet-draw-toolbar .leaflet-draw-draw-circle {
  background-position: -90px -1px;
}
.leaflet-draw-toolbar .leaflet-draw-draw-marker {
  background-position: -122px -2px;
}
.leaflet-touch .leaflet-draw-toolbar .leaflet-draw-draw-marker {
  background-position: -120px -1px;
}
.leaflet-draw-toolbar .leaflet-draw-draw-circlemarker {
  background-position: -273px -2px;
}
.leaflet-touch .leaflet-draw-toolbar .leaflet-draw-draw-circlemarker {
  background-position: -271px -1px;
}
.leaflet-draw-toolbar .leaflet-draw-edit-edit {
  background-position: -152px -2px;
}
.leaflet-touch .leaflet-draw-toolbar .leaflet-draw-edit-edit {
  background-position: -150px -1px;
}
.leaflet-draw-toolbar .leaflet-draw-edit-remove {
  background-position: -182px -2px;
}
.leaflet-touch .leaflet-draw-toolbar .leaflet-draw-edit-remove {
  background-position: -180px -1px;
}
.leaflet-draw-toolbar .leaflet-draw-edit-edit.leaflet-disabled {
  background-position: -212px -2px;
}
.leaflet-touch .leaflet-draw-toolbar .leaflet-draw-edit-edit.leaflet-disabled {
  background-position: -210px -1px;
}
.leaflet-draw-toolbar .leaflet-draw-edit-remove.leaflet-disabled {
  background-position: -242px -2px;
}
.leaflet-touch .leaflet-draw-toolbar .leaflet-draw-edit-remove.leaflet-disabled {
  background-position: -240px -2px;
}
.leaflet-mouse-marker {
  background-color: #fff;
  cursor: crosshair;
}
.leaflet-draw-tooltip {
  background: #363636;
  background: rgba(0, 0, 0, 0.5);
  border: 1px solid transparent;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  color: #fff;
  font: 12px/18px "Helvetica Neue", Arial, Helvetica, sans-serif;
  margin-left: 20px;
  margin-top: -21px;
  padding: 4px 8px;
  position: absolute;
  visibility: hidden;
  white-space: nowrap;
  z-index: 6;
}
.leaflet-draw-tooltip:before {
  border-right: 6px solid black;
  border-right-color: rgba(0, 0, 0, 0.5);
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  content: "";
  position: absolute;
  top: 7px;
  left: -7px;
}
.leaflet-error-draw-tooltip {
  background-color: #f2dede;
  border: 1px solid #e6b6bd;
  color: #b94a48;
}
.leaflet-error-draw-tooltip:before {
  border-right-color: #e6b6bd;
}
.leaflet-draw-tooltip-single {
  margin-top: -12px;
}
.leaflet-draw-tooltip-subtext {
  color: #f8d5e4;
}
.leaflet-draw-guide-dash {
  font-size: 1%;
  opacity: 0.6;
  position: absolute;
  width: 5px;
  height: 5px;
}
.leaflet-edit-marker-selected {
  background-color: rgba(254, 87, 161, 0.1);
  border: 4px dashed rgba(254, 87, 161, 0.6);
  -webkit-border-radius: 4px;
  border-radius: 4px;
  box-sizing: content-box;
}
.leaflet-edit-move {
  cursor: move;
}
.leaflet-edit-resize {
  cursor: pointer;
}
.leaflet-oldie .leaflet-draw-toolbar {
  border: 1px solid #999;
}

/* global styling */
.leaflet-control-geosearch *,
.leaflet-control-geosearch *:before,
.leaflet-control-geosearch *:after {
  box-sizing: border-box;
}

/* leaflet button styling */
.leaflet-control-geosearch .leaflet-bar-part {
  border-radius: 4px;
  border-bottom: none;
}

.leaflet-control-geosearch a.leaflet-bar-part:before,
.leaflet-control-geosearch a.leaflet-bar-part:after {
  position: absolute;
  display: block;
  content: "";
}

/* magnifying glass */
.leaflet-control-geosearch a.leaflet-bar-part:before {
  top: 15px;
  left: 13px;
  width: 6px;
  border-top: 2px solid #555;
  transform: rotateZ(45deg);
}

.leaflet-control-geosearch a.leaflet-bar-part:after {
  top: 8px;
  left: 8px;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  border: 2px solid #555;
}

/* resets for pending and error icons */
.leaflet-control-geosearch.error a.leaflet-bar-part:before,
.leaflet-control-geosearch.pending a.leaflet-bar-part:before {
  display: none;
}

.leaflet-control-geosearch.pending a.leaflet-bar-part:after,
.leaflet-control-geosearch.error a.leaflet-bar-part:after {
  left: 50%;
  top: 50%;
  width: 18px;
  height: 18px;
  margin: -9px 0 0 -9px;
  border-radius: 50%;
}

/* pending icon */
.leaflet-control-geosearch.pending a.leaflet-bar-part:after {
  content: "";
  border: 2px solid #555;
  border-top: 2px solid #f3f3f3;
  animation: spin 1s linear infinite;
}

/* error icon */
.leaflet-control-geosearch.error a.leaflet-bar-part:after {
  content: "!";
  line-height: initial;
  font-weight: 600;
  font-size: 18px;
  border: none;
}

/* search form styling */
.leaflet-control-geosearch form {
  display: none;
  position: absolute;
  top: 0;
  left: 36px;
  border-radius: 0 4px 4px 0;
  background-color: #fff;
  background-clip: padding-box;
  z-index: -1;
  height: auto;
  margin: 0;
  padding: 0 8px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
}

.leaflet-geosearch-button form.open {
  border-radius: 0 4px 4px 4px;
}
.leaflet-control-geosearch.active form {
  display: block;
}

.leaflet-geosearch-button.active .leaflet-bar-part {
  border-radius: 4px 0 0 4px;
  width: 36px;
}

.leaflet-geosearch-button form {
  max-width: 350px;
}

.leaflet-control-geosearch form input {
  min-width: 200px;
  width: 100%;
  outline: none;
  margin: 0;
  padding: 0;
  font-size: 12px;
  height: 26px;
  border: none;
  border-radius: 0 4px 4px 0;
  text-indent: 8px;
}

.leaflet-touch .leaflet-geosearch-bar form {
  border: 2px solid rgba(0, 0, 0, 0.2);
  box-shadow: none;
}

.leaflet-touch .leaflet-geosearch-bar form input {
  height: 30px;
}

.leaflet-control-geosearch .results {
  background: #fff;
}

.leaflet-control-geosearch .results > * {
  line-height: 24px;
  padding: 0 8px;
  border: 1px solid transparent;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.leaflet-control-geosearch .results.active {
  padding: 8px 0;
  border-top: 1px solid #c6c6c6;
}

.leaflet-control-geosearch .results > .active,
.leaflet-control-geosearch .results > :hover {
  background-color: #f8f8f8;
  border-color: #c6c6c6;
  cursor: pointer;
}

/* add missing border to form */
.leaflet-control-geosearch .results.active:after {
  content: "";
  display: block;
  width: 0;
  position: absolute;
  left: -2px;
  bottom: -2px;
  top: 30px;
}

.leaflet-touch .leaflet-control-geosearch .results.active:after {
  border-left: 2px solid rgba(0, 0, 0, 0.2);
}

/* animations */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.leaflet-top .leaflet-geosearch-bar,
.leaflet-bottom .leaflet-geosearch-bar {
  display: none;
}

.leaflet-geosearch-bar {
  position: relative;
  display: block;
  height: auto;
  width: 400px;
  max-width: calc(100% - 120px);
  margin: 10px auto 0;
  cursor: auto;
  z-index: 1000;
}

.leaflet-geosearch-bar form {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  border-radius: 4px;
}

.leaflet-geosearch-bar form input {
  min-width: 100%;
  width: 100%;
}

.leaflet-geosearch-bar .results.active:after {
  opacity: 0.2;
}

.leaflet-right .leaflet-control-geosearch form {
  right: 28px;
  left: initial;
  border-radius: 4px 0 0 4px;
  border-left: inherit;
  border-right: none;
}

.leaflet-control-geosearch a.reset {
  color: black;
  font-weight: bold;
  position: absolute;
  line-height: 26px;
  padding: 0 8px;
  right: 0;
  top: 0;
  cursor: pointer;
  border: none;
  text-decoration: none;
  background-color: #fff;
  border-radius: 0 4px 4px 0;
}

.leaflet-touch .leaflet-control-geosearch a.reset {
  line-height: 30px;
}

.leaflet-control-geosearch a.reset:hover {
  background: #f5f5f5;
}
