html {
  font-size: 100%; /* 62.5% of 16px = 10px */
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

a {
  text-decoration: none;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.chart-temp-legend-dot {
  display: flex;
  background-color: #cf5c4c;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  margin-right: 10px;
}

.chart-humidity-legend-dot {
  display: flex;
  background-color: #4c79cf;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  margin-right: 10px;
}

.chart-tooltip-item-row {
  display: flex;
  justify-content: space-between;
  width: min-content;
  padding: 2px 2px 2px 0;
}

.chart-tooltip-title {
  display: flex;
}
